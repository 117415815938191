import React from 'react';
import { FaTelegramPlane, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Импортируем иконки для Telegram, GitHub и LinkedIn
import ContactButton from './ContactButton'; // Путь к файлу с вашей кнопкой

function DeveloperPage() {
  const telegramBase = "https://t";
  const telegramMiddle = "elegram.me/";
  const telegramUser = "sibside";

  const githubBase = "https://git";
  const githubMiddle = "hub.com/";
  const githubUser = "sibside";

  const linkedInBase = "https://www.linke";
  const linkedInMiddle = "din.com/in/";
  const linkedInUser = "denis-sidorenko-a9a277114";

  const instagramBase = 'https://www.instagram.com/';
  const instagramUser = '_sibside'; 

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center'
  };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    textDecoration: 'none',
    color: 'inherit'
  };

  const iconStyle = {
    marginRight: 10, // Общий правый отступ для всех иконок
    marginBottom: 0 // Убираем отступ снизу, если он есть
  };

  return (
    <div style={containerStyle}>
      <ContactButton />
      <img src="/den.jpeg" alt="Сидоренко Денис" style={{ maxWidth: '300px', borderRadius: '50%' }} />
      <h1>Сидоренко Денис</h1>
      <p>
        Свяжитесь со мной:
        <a href={`${telegramBase}${telegramMiddle}${telegramUser}`} style={linkStyle} rel="noopener noreferrer" target="_blank">
          <FaTelegramPlane size={30} style={iconStyle} />@sibside
        </a>
        <a href={`${githubBase}${githubMiddle}${githubUser}`} style={linkStyle} rel="noopener noreferrer" target="_blank">
          <FaGithub size={30} style={iconStyle} />@sibside
        </a>
        <a href={`${linkedInBase}${linkedInMiddle}${linkedInUser}`} style={linkStyle} rel="noopener noreferrer" target="_blank">
          <FaLinkedin size={30} style={iconStyle} />@denis-sidorenko-a9a277114
        </a>
        <a href={`${instagramBase}${instagramUser}`} style={linkStyle} rel="noopener noreferrer" target="_blank">
        <FaInstagram size={30} style={iconStyle} /> @_sibside
      </a>
      </p>
    </div>
  );
}

export default DeveloperPage;
