import React from 'react';
import { Link } from 'react-router-dom';

function ContactButton() {
  const buttonStyle = {
    position: 'absolute',
    top: '10px',
    left: '10px', // Изменено с right на left
    padding: '10px 20px',
    background: '#808080', // Изменен цвет фона на серый
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'background-color 0.3s, transform 0.2s' // Добавляем плавные переходы
  };

  const hoverStyle = {
    backgroundColor: '#a9a9a9' // Светло-серый цвет при наведении
  };

  const activeStyle = {
    transform: 'scale(0.95)' // Эффект при нажатии
  };

  return (
    <Link to="/" style={buttonStyle} onMouseOver={e => e.target.style.backgroundColor = hoverStyle.backgroundColor}
          onMouseOut={e => e.target.style.backgroundColor = '#808080'}
          onMouseDown={e => e.target.style.transform = activeStyle.transform}
          onMouseUp={e => e.target.style.transform = 'none'}>
      На главную
    </Link>
  );
}


export default ContactButton;