import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ChoiceCard.module.css'; // Убедитесь, что стили подключены, если вы используете CSS модули

function ChoiceCard({ image, name, profession, link }) {
  return (
    <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
      <div className={styles.card}>
        <img className={styles.image} src={image} alt={name} />
        <h2 className={styles.name}>{name}</h2>
        <p className={styles.profession}>{profession}</p>
      </div>
    </Link>
  );
}

export default ChoiceCard;
