import './App.css';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ChoiceCard from './components/ChoiceCard';
import DeveloperPage from './components/DeveloperPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/developer" element={<DeveloperPage />} />
        <Route path="/" element={
          <div className="choice-container">
            <ChoiceCard
              image="/den.jpeg"
              name="Сидоренко Денис"
              profession="Архитектор Инноваций"
              link="/developer"
            />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;
